import { useEffect, useState } from "react";

import { clearLocalStorage } from "../helpers/localstorage";
import ReactPlayer from "react-player";
import { motion } from "framer-motion";
import { sendAnalyticsEvent } from "../helpers/analytics";

const variants = {
  main: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: { staggerChildren: 0.1 },
    },
    exit: {
      opacity: 0,
    },
  },
  content: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: { duration: 0.4 },
    },
  },
  videoImage: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
  },
};

const FinalVideo = ({ content }) => {
  const [text, setText] = useState({ title: "", subtitle: "", text: "" });
  useEffect(() => {
    sendAnalyticsEvent("kubus opgelost", "kubus opgelost");
    if (content !== undefined && content !== null) {
      setText(content);
    }
  }, [content]);

  return (
    <motion.div
      className="solvedMessage"
      initial="initial"
      animate="animate"
      variants={variants.main}
    >
      <div>
        <motion.h1 variants={variants.content} className="thin">
          {text.subtitle}
        </motion.h1>
        <motion.h1 variants={variants.content}>{text.title}</motion.h1>
        <motion.p variants={variants.content}>{text.text}</motion.p>
        <motion.address variants={variants.content}>
          <h2>Joop de Champs</h2>
          <a href="tel:+31653358936">06 53 35 89 36</a>
          <a href="mailto:joop.champs@loyalis.nl">joop.champs@loyalis.nl</a>
        </motion.address>
      </div>
      <motion.div className="videoImage" variants={variants.videoImage}>
        {text.videoUrl ? (
          <div className="player-wrapper">
            <ReactPlayer
              className="react-player"
              url={text.videoUrl}
              playing={true}
              width="100%"
              height="100%"
            />
          </div>
        ) : null}
        {text.image ? (
          <div
            className="image"
            style={{ backgroundImage: `url(${text.image})` }}
          ></div>
        ) : null}
      </motion.div>
      <span
        className="restart"
        onClick={() => {
          clearLocalStorage();
        }}
      >
        Opnieuw beginnen
      </span>
    </motion.div>
  );
};
export default FinalVideo;
