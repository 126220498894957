export const sendAnalyticsEvent = (label, action = "cta click") => {
  const category = "Kubus oplosser";

  if (undefined !== window.utag) {
    window.utag.link({
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
    });
  }
};
