import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { wrap } from "popmotion";
import { sendAnalyticsEvent } from "../helpers/analytics";
import Button from "./button";
const variants = {
  slide: {
    enter: {
      opacity: 1,
    },
    center: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
      transition: { duration: 0.2 },
    },
  },
  content: {
    enter: {
      scale: 0,
      opacity: 0,
    },
    center: {
      scale: 1,
      opacity: 1,
      transition: { ease: [0.17, 0.67, 0.83, 0.67], delay: 0.8 },
    },
    exit: {},
  },
  slideTitle: {
    enter: {
      // y: 10,
      opacity: 0,
    },
    center: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5, delay: 1.1 },
    },
    exit: {
      //   x: 10,
      opacity: 0,
    },
  },
  slideText: {
    enter: {
      // y: 10,
      opacity: 0,
    },
    center: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5, delay: 1.1 },
    },
    exit: {
      //   x: -10,
      opacity: 0,
    },
  },
  slideImg: {
    enter: {
      // y: 10,
      // x: 10,
      scale: 1.1,
      opacity: 0,
    },
    center: {
      y: 0,
      x: 0,
      scale: 1,
      opacity: 1,
      transition: { duration: 1, delay: 0.3 },
    },
    exit: {
      //   x: -100,
      opacity: 0,
    },
  },
  buttons: {
    enter: {
      y: 0,
      opacity: 0,
    },
    center: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5, delay: 1.1 },
    },
    exit: {
      //   x: -10,
      opacity: 0,
    },
  },
};

const SlideShow = ({ slides, slideDuration }) => {
  const [page, setPage] = useState(0);
  const slideIndex = wrap(0, slides.length, page);
  const [loop, setLoop] = useState(null);
  const [slideShowStarted, setSlideShowStarted] = useState(false);

  const nextPage = () => {
    setPage((page) => page + 1);
  };

  const resetLoop = () => {
    clearInterval(loop);
    setLoop(
      setInterval(() => {
        nextPage();
      }, slideDuration)
    );
  };

  useEffect(() => {
    setTimeout(() => {
      resetLoop();
      setSlideShowStarted(true);
    }, 1000);

    return function cleanup() {
      clearInterval(loop);
    };
  }, []);
  if (!slideShowStarted) {
    return <div className="slideshow" />;
  }
  return (
    <div className="slideshow">
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={page}
          src={slides[slideIndex]?.image}
          variants={variants.slide}
          initial="enter"
          animate="center"
          exit="exit"
          className="slide"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
        >
          <div className="imageContainer">
            <motion.div
              variants={variants.slideImg}
              className="image"
              style={{ backgroundImage: `url(${slides[slideIndex]?.image}` }}
            />
          </div>
          <motion.div
            className="content"
            variants={variants.content}
            style={{ originX: 0, originY: 0 }}
          >
            <motion.h2 variants={variants.slideTitle}>
              {slides[slideIndex]?.title}
            </motion.h2>

            <motion.div variants={variants.slideText}>
              {slides[slideIndex]?.text}
            </motion.div>

            <motion.a
              variants={variants.buttons}
              href={slides[slideIndex].link}
              target="_blank"
              onClick={() => {
                sendAnalyticsEvent(`Lees meer ${slides[slideIndex].title}`);
              }}
            >
              <Button
                type="quaternary"
                label={slides[slideIndex].buttonText}
                clickButton={(e) => {
                  return;
                }}
              />
            </motion.a>
          </motion.div>
          <div></div>
        </motion.div>
      </AnimatePresence>

      <div className="sliderPagination">
        {slides.map((slide, key) => (
          <div
            key={`page-${key}`}
            className={slideIndex === key ? "active" : ""}
            onClick={() => {
              if (key !== slideIndex) {
                resetLoop();
                setPage(key);
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};
export default SlideShow;
