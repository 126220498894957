import { motion, AnimatePresence } from "framer-motion";
const Notifications = ({ notifications, setNotifications }) => {
  return (
    <div className="notifications">
      <AnimatePresence exitBeforeEnter initial={false}>
        {notifications.map((notification, id) => (
          <motion.div
            key={`notification_${notification.timeStamp}`}
            className={`notification`}
            positionTransition
            initial={{ opacity: 0, y: -50, scale: 1 }}
            animate={{
              opacity: 1,
              y: 0,
              scale: 1,
              transition: { duration: 0.5 },
            }}
            exit={{
              opacity: 0,
              y: -50,
              // scale: 0.5,
              transition: { duration: 0.2 },
            }}
            onClick={() => {
              setNotifications([]);
            }}
          >
            <h1>{notification.title}</h1>
            <p dangerouslySetInnerHTML={{ __html: notification.text }} />
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

export default Notifications;
