/* https://codedaily.io/tutorials/72/Creating-a-Reusable-Window-Event-Listener-with-useEffect-and-useCallback */
import { useEffect } from "react";

export const useGlobalMouseUp = (callback) => {
  return useWindowEvent("mouseup", callback);
};

export const useGlobalMouseMove = (callback) => {
  return useWindowEvent("mousemove", callback);
};

export const useWindowEvent = (event, callback) => {
  useEffect(() => {
    window.addEventListener(event, callback);
    return () => window.removeEventListener(event, callback);
  }, [event, callback]);
};
