import { useState, useEffect } from "react";
import { motion, useMotionValue, useSpring } from "framer-motion";

const Cursor = ({ selectedFillColor, scene }) => {
  const cursorX = useMotionValue(-100);
  const cursorY = useMotionValue(-100);

  const springConfig = { damping: 30, stiffness: 400 };
  const cursorXSpring = useSpring(cursorX, springConfig);
  const cursorYSpring = useSpring(cursorY, springConfig);

  //   const [cursorXY, setCursorXY] = useState({ x: -100, y: -100 });
  useEffect(() => {
    const moveCursor = (e) => {
      cursorX.set(e.clientX - 25);
      cursorY.set(e.clientY - 25);
    };
    window.addEventListener("mousemove", moveCursor);
    return () => {
      window.removeEventListener("mousemove", moveCursor);
    };
  }, []);

  if (!selectedFillColor || scene >= window.SCENE_SHOW_SOLUTION_STEPS) {
    return <></>;
  }
  return (
    <motion.div
      className="cursorContainer"
      style={{
        translateX: cursorX,
        translateY: cursorY,
      }}
    >
      <motion.div
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        transition={{
          yoyo: Infinity,
          duration: 0.5,
          //   ease: "easeInOut",
        }}
        className={`cursor ${selectedFillColor || ""}`}
      />
    </motion.div>
  );
};
export default Cursor;
