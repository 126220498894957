import { useCallback, useRef } from "react";
import ColorPallete from "./colorPallette";
import Cubie from "./cubie";
import VisualStepExplainer from "./visualStepExplainer";
import { useSpring, motion, useMotionValue } from "framer-motion";
import { useState, useEffect } from "react";
import { ReactComponent as ArrowUp } from "../img/chevron-up-solid.svg";
import { ReactComponent as ArrowDown } from "../img/chevron-down-solid.svg";
import { useGlobalMouseMove } from "../hooks/useWindowEvents";
import Button from "./button";
let isRotating = false;
const Cube = ({
  scene,
  highlightedFace,
  solutionSteps,
  hideCube,
  cubeState,
  updateCubeColor,
  setScene,
  showSolutionStep,
  cubeRotation,
  setSelectedFillColor,
  selectedFillColor,
  previousScene,
  nextScene,
  appDesiredPerspective,
  setSolutionStep,
  nextSolutionStep,
  previousSolutionStep,
  setCubeState,
  getCubeStateFromString,
}) => {
  const defaultPerpspective = { x: -20, y: -45 };
  const rotateX = useSpring(-20, { damping: 13, stiffness: 70 });
  const rotateY = useSpring(-45, { damping: 13, stiffness: 70 });

  const [hiddenCursorColor, setHiddenCursorColor] = useState(null);

  const cubeRef = useRef(null);

  if (scene === window.SCENE_INTRO)
    cubeState = {
      up: "FURBUBUFL", // up
      right: "BDDLRBFLL", // right
      front: "BDUUFDBFR", // front
      down: "LUUFDURDF", // down
      left: "DLRFLRFLD", // left
      back: "BBLRBRURD", // back
    };

  const [randomKey, setRandomKey] = useState(0);
  const randomState = [
    { x: -20, y: -45 },
    { x: 20, y: -225 },
    { x: 120, y: -225 },
    { x: 40, y: -100 },
    { x: 90, y: 180 },
    { x: -45, y: 45 },
  ];

  useEffect(() => {
    if (scene === window.SCENE_INTRO) {
      rotateX.set(randomState[randomKey].x);
      rotateY.set(randomState[randomKey].y);

      setTimeout(() => {
        if (scene === window.SCENE_INTRO) {
          if (randomKey < randomState.length - 1) {
            setRandomKey(randomKey + 1);
          } else {
            setRandomKey(0);
          }
        }
      }, 1200);
    } else {
    }
    if (scene === window.SCENE_START) {
      setTimeout(() => {
        rotateX.set(defaultPerpspective.x);
        rotateY.set(defaultPerpspective.y);
      }, 10);
    }
    if (appDesiredPerspective !== null) {
      switch (appDesiredPerspective) {
        case "random1":
          rotateX.set(-30);
          rotateY.set(-45);

          break;
        case "random2":
          rotateX.set(20);
          rotateY.set(-225);

          break;
        default:
          break;
      }
    }
  }, [randomKey, scene, appDesiredPerspective]);

  let rotateFunction = null;
  const rotateCube = (axis, value) => {
    isRotating = true;
    switch (axis) {
      default:
      case "x":
        rotateFunction = () => {
          rotateY.set(rotateY.get() + value);
          if (isRotating) {
            setTimeout(() => {
              rotateFunction();
            }, 50);
          }
        };

        break;

      case "y":
        rotateFunction = () => {
          rotateX.set(rotateX.get() + value);
          if (isRotating) {
            setTimeout(() => {
              rotateFunction();
            }, 50);
          }
        };
        break;
    }
    rotateFunction();
  };
  const renderFaces = () => {
    return Object.keys(cubeState).map((face) => {
      const cubies = Array(9)
        .fill(1)
        .map((el, i) => (
          <Cubie
            key={`${face}-${i}`}
            color={cubeState[face][i]}
            face={face}
            index={i}
            updateCubeColor={updateCubeColor}
            scene={scene}
            setScene={setScene}
          />
        ));
      return (
        <div key={`face-${face}`} className={`cube_face face-${face}`}>
          {cubies}
        </div>
      );
    });
  };

  const circleMotionValues = {
    x: useMotionValue(0),
    y: useMotionValue(0),
  };
  const circleSpringValues = {
    x: useSpring(circleMotionValues.x, { stiffness: 300, damping: 50 }),
    y: useSpring(circleMotionValues.y, { stiffness: 300, damping: 50 }),
  };

  const updateMotionVars = useCallback((e) => {
    if (cubeRef.current === null) {
      return true;
    }

    const cubeDimensions = cubeRef.current.getBoundingClientRect();
    const center = [
      cubeDimensions.x + cubeDimensions.width / 2,
      cubeDimensions.y + cubeDimensions.height / 2,
    ];

    const deltaX = (center[0] - e.clientX) * 0.05;
    const deltaY = (center[1] - e.clientY) * 0.05;

    circleMotionValues.x.set(deltaX);
    circleMotionValues.y.set(deltaY);
  }, []);

  useGlobalMouseMove(updateMotionVars);

  return (
    <div className={`cubecontainer`}>
      {scene >= window.SCENE_ASK_TO_COLOR_ALL && (
        <div
          className={`cubecontrols ${
            scene >= window.SCENE_SHOW_SOLUTION_STEPS ? "hide" : ""
          } `}
        >
          <div
            className="up"
            onMouseEnter={() => {
              setHiddenCursorColor(selectedFillColor);
              setSelectedFillColor("transparent");
            }}
            onMouseLeave={() => {
              setSelectedFillColor(hiddenCursorColor);
            }}
            onClick={() => {
              rotateX.set(rotateX.get() + 45);
            }}
          >
            <ArrowUp />
          </div>
          <div
            className="down"
            onMouseEnter={() => {
              setHiddenCursorColor(selectedFillColor);
              setSelectedFillColor("transparent");
            }}
            onMouseLeave={() => {
              setSelectedFillColor(hiddenCursorColor);
            }}
            onClick={() => {
              rotateX.set(rotateX.get() - 45);
            }}
          >
            <ArrowDown />
          </div>
        </div>
      )}
      <div className="scenecontainer">
        <motion.div
          className="backgroundCircle"
          style={{
            x: circleSpringValues.x,
            y: circleSpringValues.y,
          }}
        />

        <div className={`scene ${hideCube ? "hideCube" : ""}`}>
          <motion.div
            ref={cubeRef}
            className={`cube  ${
              highlightedFace
                ? "has-highlighted-face highlighted-" + highlightedFace
                : ""
            }
              ${
                scene >= window.SCENE_COLOR_FIRST_CUBIE &&
                scene < window.SCENE_SHOW_SOLUTION_STEPS
                  ? "editable"
                  : ""
              }
              
              `}
            style={{
              transform: `translateZ(100px)`,
              rotateX: rotateX,
              rotateY: rotateY,
            }}
          >
            {renderFaces()}

            {solutionSteps && (
              <>
                <VisualStepExplainer
                  face="front"
                  highlightedFace={highlightedFace}
                  move={solutionSteps[showSolutionStep || 0]["move"]}
                />
                <VisualStepExplainer
                  face="up"
                  highlightedFace={highlightedFace}
                  move={solutionSteps[showSolutionStep || 0]["move"]}
                />
                <VisualStepExplainer
                  face="down"
                  highlightedFace={highlightedFace}
                  move={solutionSteps[showSolutionStep || 0]["move"]}
                />
                <VisualStepExplainer
                  face="left"
                  highlightedFace={highlightedFace}
                  move={solutionSteps[showSolutionStep || 0]["move"]}
                />
                <VisualStepExplainer
                  face="right"
                  highlightedFace={highlightedFace}
                  move={solutionSteps[showSolutionStep || 0]["move"]}
                />
                <VisualStepExplainer
                  face="back"
                  highlightedFace={highlightedFace}
                  move={solutionSteps[showSolutionStep || 0]["move"]}
                />
              </>
            )}
          </motion.div>
        </div>
      </div>
      {scene >= window.SCENE_SHOW_COLOR_PALETTE &&
        scene < window.SCENE_SHOW_SOLUTION_STEPS && (
          <ColorPallete
            selectedFillColor={selectedFillColor}
            setSelectedFillColor={setSelectedFillColor}
            scene={scene}
            nextScene={nextScene}
          />
        )}

      {scene >= window.SCENE_SHOW_SOLUTION_STEPS && (
        <div className="solutionSteps">
          <motion.div
            key={`step-description-${showSolutionStep}`}
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
          >
            {/* <TextStepExplainer
              move={solutionSteps[showSolutionStep || 0]["move"]}
            /> */}

            {/* {solutionSteps.map((step, index) => (
              <div
                onClick={() => {
                  setSolutionStep(index, true);
                }}
                key={index}
                style={{
                  color: index === showSolutionStep ? "red" : "grey",
                  display: "inline-block",
                  margin: "1em",
                }}
              >
                {step.move}
              </div>
            ))} */}
          </motion.div>
          <div className="stepButtons">
            {showSolutionStep === 0 && (
              <Button
                label="vorige"
                type="tertiary-back-disabled"
                clickButton={(e) => {
                  e.preventDefault();
                  previousScene();
                }}
              />
            )}
            {showSolutionStep !== 0 && (
              <Button
                label="vorige"
                type="tertiary-back"
                clickButton={(e) => {
                  e.preventDefault();
                  previousSolutionStep();
                }}
              />
            )}
            <Button
              label="volgende"
              type="tertiary"
              clickButton={(e) => {
                e.preventDefault();
                if (showSolutionStep < solutionSteps.length - 1) {
                  nextSolutionStep();
                } else {
                  setCubeState(
                    getCubeStateFromString(
                      solutionSteps[showSolutionStep].stateAfterMove
                    )
                  );
                  nextScene();
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Cube;
