// import redoSVG from "../img/redo-solid.svg";
import { clearLocalStorage } from "../helpers/localstorage";
import { motion, AnimatePresence } from "framer-motion";
import Button from "./button";

const explainerVariants = {
  main: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: { staggerChildren: 0.1 },
    },
    exit: {
      opacity: 0,
    },
  },
  content: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: { duration: 0.4 },
    },
  },
};

const StepExplainer = ({
  scene,
  nextScene,
  previousScene,
  colorMisMatch,
  cubeSolverInitialized,
  appIsCalculating,
  setAppIsCalculating,
  calculateSolution,
  stepInfo,
}) => {
  let showPrevious = false;

  let showScenarioTekst = false;

  showScenarioTekst = stepInfo[scene];

  const solveButtonEnabled =
    !colorMisMatch && cubeSolverInitialized && !appIsCalculating;

  // if (!showScenarioTekst) {
  //   return <div className="sceneExplainer transparent" />;
  // }
  return (
    <motion.div
      className="sceneExplainer"
      initial={{ x: 0 }}
      animate={{ x: showScenarioTekst ? 0 : "-100%" }}
    >
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={`explainer-${scene}`}
          variants={explainerVariants.main}
          animate="animate"
          initial="initial"
          exit="exit"
        >
          <div className="title">
            {showScenarioTekst.subtitle ? (
              <motion.h1 className="thin" variants={explainerVariants.content}>
                {showScenarioTekst.subtitle}
              </motion.h1>
            ) : null}
            <motion.h1 variants={explainerVariants.content}>
              {showScenarioTekst.title}
            </motion.h1>
          </div>
          <div className="explainer">
            <motion.p
              dangerouslySetInnerHTML={{
                __html: showScenarioTekst.text,
              }}
              variants={explainerVariants.content}
            />

            {showPrevious && (
              <button onClick={() => previousScene()}>Lalalalal &lt;</button>
            )}
            {scene !== window.SCENE_CUBE_IS_COMPLETELY_COLLORED &&
              undefined !== showScenarioTekst.btn_text && (
                <motion.div
                  onClick={() => {
                    nextScene();
                  }}
                  variants={explainerVariants.content}
                >
                  <Button
                    label={showScenarioTekst.btn_text}
                    type="primary"
                    clickButton={() => {
                      nextScene();
                    }}
                  />
                </motion.div>
              )}
            {scene === window.SCENE_CUBE_IS_COMPLETELY_COLLORED && (
              <motion.div variants={explainerVariants.content}>
                {!cubeSolverInitialized ? (
                  <Button
                    label="even geduld..."
                    type="primary-disabled"
                    clickButton={(e) => {
                      e.preventDefault();
                    }}
                    style={{ opacity: 0.5 }}
                  />
                ) : (
                  <Button
                    label={showScenarioTekst.btn_text}
                    type="primary"
                    clickButton={(e) => {
                      e.preventDefault();
                      setAppIsCalculating(true);
                      calculateSolution();
                    }}
                  />
                )}
              </motion.div>
            )}
          </div>
          <div className="footer">
            {scene !== window.SCENE_INTRO ? (
              <span
                onClick={() => {
                  clearLocalStorage();
                }}
                className="restart"
              >
                Opnieuw beginnen
              </span>
            ) : null}
          </div>
        </motion.div>
      </AnimatePresence>
    </motion.div>
  );
};

export default StepExplainer;
