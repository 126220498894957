const Navigation = () => {
  return (
    <div className="navigation">
      <div className="topNavigation">
        <div className="container">
          <div className="topNavigation__content">
            <div className="targetaudience_switch">
              <a
                href="https://www.loyalis.nl"
                target="_blank"
                className="targetaudience_switch__item"
              >
                Particulier
              </a>

              <a
                href="https://www.loyalis.nl/zakelijk"
                target="_blank"
                className="targetaudience_switch__item"
              >
                <span>Zakelijk</span>
                <div className={`tooltip`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="48"
                    height="13"
                    viewBox="0 0 48 13"
                  >
                    <path
                      d="M-0.6,13.7h3.2c0.8,0,1.5,0,2.3,0c5.6-0.1,11-2.4,15-6.3c2-2.1,3.6-4.5,4.7-7.2c0,0,0,0,0,0v0
	c1.1,2.7,2.7,5.2,4.7,7.2c4,3.9,9.4,6.2,15,6.3c0.8,0,1.5,0,2.3,0h1.6"
                    />
                  </svg>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="navigation__main-navigation">
        <div className="container container--grid-medium container--no-padding">
          <a
            href="https://www.loyalis.nl"
            className="navigation__logo"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="138.611"
              height="61.563"
              viewBox="0 0 138.611 61.563"
            >
              <g className="logo">
                <path
                  d="M289.051,262.533c-3.811,0-4.379-3.846-4.379-6.138s.568-6.137,4.379-6.137,4.383,3.846,4.383,6.137-.571,6.138-4.383,6.138m-7.667-6.138c0,5.363,2.866,8.564,7.667,8.564s7.669-3.2,7.669-8.564-2.866-8.562-7.669-8.562-7.667,3.2-7.667,8.562"
                  transform="translate(-258.03 -233.475)"
                  fill="#0057a3"
                />
                <path
                  d="M290.35,264.119a19.553,19.553,0,0,1-6.968-.042A13.627,13.627,0,1,0,299.2,241.882a19.739,19.739,0,0,1,2.34,6.661,13.567,13.567,0,0,1-11.186,15.576"
                  transform="translate(-259.372 -229.479)"
                  fill="#ff7920"
                />
                <path
                  d="M274.826,233.012a19.525,19.525,0,0,1,6.97.04,13.629,13.629,0,1,0-15.817,22.2,19.838,19.838,0,0,1-2.339-6.661,13.57,13.57,0,0,1,11.186-15.576"
                  transform="translate(-243.763 -221.811)"
                  fill="#ff7920"
                />
              </g>
              <g className="letters">
                <path
                  d="M592.092,232.51a14.414,14.414,0,0,0-5.729-1.123c-6.06,0-9.331,3.884-9.331,7.542,0,5.037,3.789,6.415,7.138,7.6,2.878,1.018,5.163,1.859,5.163,4.766,0,2.393-2.409,4.34-5.617,4.34a11.231,11.231,0,0,1-5.794-1.509l-.411-.276-1.559,3.46.323.214a15.861,15.861,0,0,0,7.851,1.838,11.281,11.281,0,0,0,8.208-3.216,7.25,7.25,0,0,0,2.124-4.947c0-5.3-4.007-6.868-7.537-8.14-2.788-1.008-4.961-1.744-4.961-4.27a3.273,3.273,0,0,1,.962-2.276,5.3,5.3,0,0,1,4.031-1.585,9.1,9.1,0,0,1,4.884,1.463l.255.171V232.51Z"
                  transform="translate(-455.847 -222.431)"
                  fill="#0057a3"
                />
                <path
                  d="M362.567,254.374c-.177-.762-.629-2.232-.95-3.214l-6.491-17.886h-5.2l10.223,26.106-4.856,10.9h5.258l14.367-37H370.2L363.64,251.16c-.5,1.394-.714,2.073-1.024,3.214Z"
                  transform="translate(-304.059 -223.698)"
                  fill="#0057a3"
                />
                <path
                  d="M449.451,257.321V240.386a8.658,8.658,0,0,0-2.256-6.315c-1.766-1.8-4.5-2.711-8.1-2.711a23.13,23.13,0,0,0-6.631,1.05v3.924a17.259,17.259,0,0,1,5.916-1.1c2.378,0,3.957.483,4.943,1.485a4.581,4.581,0,0,1,1.157,3.334v.8c-3.66.357-7.661.934-10.809,2.412-3.606,1.688-5.357,4.247-5.357,7.822a7.368,7.368,0,0,0,2.036,5.374c1.879,1.916,4.956,2.883,9.148,2.883a21.94,21.94,0,0,0,9.6-1.836l.348-.183Zm-4.969-2.149a12.176,12.176,0,0,1-4.673.823c-2.528,0-4.185-.555-5.257-1.646a4.809,4.809,0,0,1-1.143-3.36c0-4.629,3.462-5.939,11.073-6.861v11.044Z"
                  transform="translate(-356.702 -222.413)"
                  fill="#0057a3"
                />
                <path
                  d="M517.733,204.12h-4.969v36.266h4.969V204.12Z"
                  transform="translate(-413.413 -204.12)"
                  fill="#0057a3"
                />

                <path
                  d="M546.281,207.169a3.048,3.048,0,1,1,3.049,3.048,3.051,3.051,0,0,1-3.049-3.048"
                  transform="translate(-435.922 -204.12)"
                  fill="#0057a3"
                />

                <path
                  d="M552.962,233.274h-4.969v26.69h4.969v-26.69Z"
                  transform="translate(-437.071 -223.698)"
                  fill="#0057a3"
                />
                <path
                  d="M228.409,240.39v-.022a16.4,16.4,0,0,1-2.846-4.128H215.445V204.12H210.28v36.264h0v.007Z"
                  transform="translate(-210.28 -204.12)"
                  fill="#0057a3"
                />
                <g transform="translate(16.128 51.773)">
                  <g>
                    <g>
                      <path
                        d="M260.535,375.537a2.078,2.078,0,0,0,2.046,1.926,2.3,2.3,0,0,0,2.046-1.017h1.137a3.232,3.232,0,0,1-3.2,1.927,3.168,3.168,0,0,1-3.183-3.2,3.231,3.231,0,0,1,3.263-3.25,3.331,3.331,0,0,1,3.249,3.611Zm4.212-.9a2.047,2.047,0,0,0-2.113-1.805,2.11,2.11,0,0,0-2.1,1.805Z"
                        transform="translate(-259.384 -368.583)"
                      />
                      <path
                        d="M284.111,375.537a2.079,2.079,0,0,0,2.046,1.926,2.3,2.3,0,0,0,2.046-1.017h1.137a3.233,3.233,0,0,1-3.2,1.927,3.168,3.168,0,0,1-3.183-3.2,3.232,3.232,0,0,1,3.263-3.25,3.331,3.331,0,0,1,3.249,3.611Zm4.212-.9a2.047,2.047,0,0,0-2.113-1.805,2.111,2.111,0,0,0-2.1,1.805Z"
                        transform="translate(-275.217 -368.583)"
                      />
                      <path
                        d="M308.758,373a3.4,3.4,0,0,1,2.206-1.07,1.775,1.775,0,0,1,1.9,1.793v3.623h.95v.9h-3.156v-.9h1.056V374c0-.641-.307-1.109-.856-1.109a2.99,2.99,0,0,0-2.1,1.03v3.423h1.016v.9h-3.2v-.9h1.03v-4.386h-1.03v-.9h2.18V373Z"
                        transform="translate(-291.077 -368.583)"
                      />
                      <path
                        d="M345.964,372.929a3.022,3.022,0,0,1,1.952-1,1.9,1.9,0,0,1,1.712,1.111,3.014,3.014,0,0,1,2.3-1.111c1.257,0,1.765,1.151,1.765,2.221v3.2h.87v.9h-2.955v-.9h.935v-3.316c0-.722-.307-1.136-1.069-1.136a2.5,2.5,0,0,0-1.645,1.043v3.41h.883v.9H347.77v-.9h.909v-3.316c0-.722-.308-1.136-1.07-1.136a2.5,2.5,0,0,0-1.645,1.043v3.41h.936v.9h-3.062v-.9h.976v-4.386h-.976v-.9h2.126v.869Z"
                        transform="translate(-316.099 -368.583)"
                      />
                      <path
                        d="M381.758,375.537a2.079,2.079,0,0,0,2.046,1.926,2.3,2.3,0,0,0,2.046-1.017h1.136a3.233,3.233,0,0,1-3.2,1.926,3.167,3.167,0,0,1-3.182-3.2,3.231,3.231,0,0,1,3.262-3.25,3.332,3.332,0,0,1,3.25,3.611Zm4.212-.9a2.047,2.047,0,0,0-2.113-1.805,2.111,2.111,0,0,0-2.1,1.805Z"
                        transform="translate(-340.792 -368.583)"
                      />
                      <path
                        d="M406.338,373.277h.027a2.362,2.362,0,0,1,2.38-1.351v1.017c-1.9.134-2.34.721-2.34,2.286v2.113h1.151v.9h-3.33v-.9h1.03v-4.385h-1.03v-.9h2.113v1.217Z"
                        transform="translate(-356.652 -368.583)"
                      />
                      <path
                        d="M421.123,371.4v-.9h1.017v-7.863h-.963v-.9h2.113v6.312l2.22-1.938h-.829v-.9h3v.9h-.708l-2.314,2.059,2.314,2.326h.708v.9h-2.9v-.9h.762l-2.246-2.246v2.246h.775v.9Z"
                        transform="translate(-368 -361.749)"
                      />
                      <g id="Group_1" transform="translate(64.33 3.343)">
                        <path
                          d="M458.187,372.335v.9h-.936l1.4,3.6h.027l1.552-3.6h-1v-.9h2.888v.9h-.843l-2.3,5.282h-.789l-2.126-5.282h-.816v-.9Z"
                          transform="translate(-455.245 -372.2)"
                        />
                        <path
                          d="M485.448,377.342h1.043v.9h-2.058V377.3h-.041a2.687,2.687,0,0,1-2.1,1.071,3.228,3.228,0,0,1,0-6.447,2.731,2.731,0,0,1,2.1,1.07h.041v-.936h2.058v.9h-1.043v4.385Zm-3.049.121a2.136,2.136,0,0,0,2.006-2.327,2.109,2.109,0,0,0-2.006-2.3,2.335,2.335,0,0,0,0,4.627Z"
                          transform="translate(-471.352 -371.926)"
                        />
                        <path
                          d="M507.269,373a3.4,3.4,0,0,1,2.207-1.07,1.774,1.774,0,0,1,1.9,1.793v3.623h.949v.9h-3.156v-.9h1.057V374c0-.641-.307-1.109-.856-1.109a2.991,2.991,0,0,0-2.1,1.03v3.423h1.016v.9h-3.2v-.9h1.03v-4.385h-1.03v-.9h2.18V373Z"
                          transform="translate(-488.718 -371.926)"
                        />
                        <path
                          d="M548.2,377.342h1.042v.9h-2.06V377.3h-.04a2.69,2.69,0,0,1-2.1,1.071,3.228,3.228,0,0,1,0-6.447,2.734,2.734,0,0,1,2.1,1.07h.04v-.936h2.06v.9H548.2v4.385Zm-3.049.121a2.136,2.136,0,0,0,2.005-2.327,2.109,2.109,0,0,0-2.005-2.3,2.335,2.335,0,0,0,0,4.627Z"
                          transform="translate(-513.491 -371.926)"
                        />
                        <path
                          d="M570.754,387.033a.842.842,0,0,1-.855.843.831.831,0,0,1-.843-.843.851.851,0,0,1,.843-.856.861.861,0,0,1,.855.856Z"
                          transform="translate(-531.675 -381.496)"
                        />
                        <path
                          d="M580.662,378.238h-.9v-2.006h.9a2,2,0,0,0,1.98,1.23c.534,0,1.51-.054,1.51-.9,0-1.578-4.386.094-4.386-2.861,0-1.284,1.11-1.779,2.246-1.779a2.931,2.931,0,0,1,1.872.709v-.575h.9v1.885h-.949a1.452,1.452,0,0,0-.615-.815,1.747,1.747,0,0,0-.976-.294c-.789,0-1.43.148-1.43.843,0,1.551,4.386.133,4.386,2.795,0,1.377-1.177,1.9-2.407,1.9a2.786,2.786,0,0,1-2.126-.75v.615Z"
                          transform="translate(-538.867 -371.926)"
                        />
                      </g>
                      <path
                        d="M603.169,387.033a.843.843,0,0,1-.856.843.831.831,0,0,1-.842-.843.851.851,0,0,1,.842-.856.862.862,0,0,1,.856.856Z"
                        transform="translate(-489.113 -378.154)"
                      />
                      <path
                        d="M613.477,373.279h.028a2.361,2.361,0,0,1,2.38-1.351v1.017c-1.9.133-2.34.721-2.34,2.286v2.113h1.149v.9h-3.33v-.9h1.029v-4.385h-1.029v-.9h2.113v1.217Z"
                        transform="translate(-495.756 -368.585)"
                      />
                      <path
                        d="M628.821,387.033a.842.842,0,0,1-.856.843.832.832,0,0,1-.844-.843.851.851,0,0,1,.844-.856.862.862,0,0,1,.856.856Z"
                        transform="translate(-506.338 -378.154)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </a>
          <nav className="navigation__menu navigation__menu-main"></nav>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
