import "./App.scss";
import { useState, useEffect } from "react";
import {
  getCubeStateFromLocalStorage,
  writeCubeStateToLocalStorage,
} from "./helpers/localstorage";

import {
  areAllCubiesFilled,
  amountOfCubiesLeftToBeFilled,
  getCubeStateString,
  getCubeStateFromString,
} from "./helpers/cube-fill-proces";

import ScenarioExplainer from "./components/scenarioExplainer";
import Cube from "./components/cube";
import Navigation from "./components/navigation";
import Footer from "./components/footer";
import Notifications from "./components/notifications";
import Cursor from "./components/cursor";

import SlideShow from "./components/slideshow";

import FinalVideo from "./components/finalVideo";
import { sendAnalyticsEvent } from "./helpers/analytics";
import { useFetch } from "./hooks/useFetch";
window.cubeColors = "FRUDLB";
window.cubeColorNames = {
  F: "donker blauw",
  R: "oranje",
  U: "roze",
  D: "grijs",
  L: "licht blauw",
  B: "wit",
};
window.SCENE_INTRO = 0;
window.SCENE_START = 1;
window.SCENE_SHOW_COLOR_PALETTE = 2;
window.SCENE_COLOR_FIRST_CUBIE = 3;
window.SCENE_ASK_TO_COLOR_ALL = 4;
// window.SCENE_SHOW_3D_CONTROLS = 5;
// window.SCENE_CONTINUE_TO_COLOR_ALL = 5;
window.SCENE_CUBE_IS_COMPLETELY_COLLORED = 5;
window.SCENE_SHOW_SOLUTION_STEPS = 6;
window.SCENE_CUBE_SOLVED = 7;

function App() {
  const [appIsCalculating, setAppIsCalculating] = useState(false);
  const [cubeSolverInitialized, setCubeSolverInitialized] = useState(false);
  const [hideCube, setHideCube] = useState(false);

  useEffect(() => {
    if (window.webworker === undefined) {
      // window.webworker = new window.Worker(
      //   "https://www.loyalis.nl/-/media/system/project/loyalis/cubewebworker.js"
      // );
      window.webworker = new window.Worker("cubeWebWorker.js");
      window.webworker.addEventListener("message", (event) => {
        switch (event.data.msg) {
          default:
            console.log("UNHANDELED WEBWORKER EVENT:", event.data);
            break;
          case "init succesfull":
            setCubeSolverInitialized(true);
            break;
          case "solved":
            setSolutionSteps(event.data.solution);
            setShowSolutionStep(0);
            setCubeRotation(
              getPreferedPerspective(event.data.solution[0]["move"])
            );
            setScene(window.SCENE_SHOW_SOLUTION_STEPS);
            break;
          // case "timeout":
          // setNewNotification("Je kubus lijkt niet helemaal te kloppen.");
          // break;
        }
        setAppIsCalculating(false);
      });
      window.webworker.addEventListener(
        "error",
        (event) => {
          console.log("error", event);
        },
        false
      );
      window.webworker.postMessage({ cmd: "init" });
    }

    if (appIsCalculating) {
      const timer = setTimeout(() => {
        console.log("Timer gaat");

        window.webworker.terminate();
        delete window.webworker;

        setNewNotification("Je kubus lijkt niet helemaal te kloppen.");
        setAppIsCalculating(false);
        setCubeSolverInitialized(false);
      }, 3000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [appIsCalculating]);
  const searchParams = new URLSearchParams(document.location.search);
  const localstorageState = getCubeStateFromLocalStorage();
  const [cubeState, setCubeState] = useState(
    localstorageState
      ? localstorageState
      : {
          back: "----B----",
          left: "----L----",
          down: "----D----",
          up: "----U----",
          right: "----R----",
          front: "----F----",
        }

    // -----
    // front: "FFFFFFFFF", // front
    // right: "RRRRRRRRR", // right
    // up: "UUUUUUUUU", // up
    // down: "DDDDDDDDD", // down
    // left: "LLLLLLLLL", // left
    // back: "BBBBBBBBB", // back
    // ----
  );

  const [cubeRotation, setCubeRotation] = useState("random1");
  const [colorMisMatch, setColorMisMatch] = useState(false);
  const [solutionSteps, setSolutionSteps] = useState(false);
  const [showSolutionStep, setShowSolutionStep] = useState(null);

  const [appDesiredPerspective, setAppDesiredPerspective] = useState(null);
  const [notifications, setNotifications] = useState([]);

  if (searchParams.get("fill") !== null) {
    writeCubeStateToLocalStorage({
      up: "FURBUBUFL", // up
      right: "BDDLRBFLL", // right
      front: "BDUUFDBFR", // front
      down: "LUUFDURDF", // down
      left: "DLRFLRFLD", // left
      back: "BBLRBRURD", // back
    });
    //redirect to current url without params
    window.location = window.location.pathname;
  }
  if (searchParams.get("empty") !== null) {
    writeCubeStateToLocalStorage({
      back: "----B----",
      left: "----L----",
      down: "----D----",
      up: "----U----",
      right: "----R----",
      front: "----F----",
    });
    //redirect to current url without params
    window.location = window.location.pathname;
  }

  const setNewNotification = (
    text,
    title = "Er lijkt iets niet te kloppen."
  ) => {
    setNotifications([{ title, text, timeStamp: new Date().getTime() }]);
  };

  const cubeStateString = getCubeStateString(cubeState);

  const [scene, setScene] = useState(() => {
    if (areAllCubiesFilled(cubeStateString)) {
      return window.SCENE_CUBE_IS_COMPLETELY_COLLORED;
    } else {
      if (amountOfCubiesLeftToBeFilled(cubeStateString) < 48) {
        return window.SCENE_ASK_TO_COLOR_ALL;
      }
    }

    return window.SCENE_INTRO;
  });
  const [selectedFillColor, setSelectedFillColor] = useState(
    scene > window.SCENE_COLOR_FIRST_CUBIE ? window.cubeColors[0] : false
  );

  const [allCubiesAreColored, setAllCubiesAreColored] = useState(
    areAllCubiesFilled(cubeStateString)
  );

  // const [cubeAppContent, loadingCubeAppContent] = useFetch(
  //   "https://www.loyalis.nl/-/media/system/project/loyalis/cubecontent.json"
  // );
  const [cubeAppContent, loadingCubeAppContent] = useFetch("cubeContent.json");

  const nextScene = () => {
    switch (scene) {
      case window.SCENE_INTRO:
        sendAnalyticsEvent("Start");
        break;
      case window.SCENE_START:
        sendAnalyticsEvent("Volgende stap");
        break;

      default:
        break;
    }
    setScene(scene + 1);
  };
  const previousScene = () => {
    switch (scene) {
      default:
        break;
    }
    setScene(scene - 1);
  };

  const getPreferedPerspective = (move) => {
    switch (move.charAt(0)) {
      case "R":
      case "U":
      case "F":
      default:
        setAppDesiredPerspective("random1");
        break;
      case "L-tijdelijkWeg":
      case "B-tijdelijkWeg":
      case "D-tijdelijkWeg":
        setAppDesiredPerspective("random2");
        break;
    }
  };

  const setSolutionStep = (step, hideCube = false) => {
    if (hideCube) {
      setHideCube(true);
      setTimeout(() => {
        setHideCube(false);
        setCubeState(
          getCubeStateFromString(solutionSteps[step].stateBeforeMove)
        );
        setCubeRotation(getPreferedPerspective(solutionSteps[step]["move"]));
        setShowSolutionStep(step);
      }, 200);
    } else {
      setCubeRotation(getPreferedPerspective(solutionSteps[step]["move"]));
      setShowSolutionStep(step);
      setCubeState(getCubeStateFromString(solutionSteps[step].stateBeforeMove));
    }
  };

  const nextSolutionStep = () => {
    setSolutionStep(showSolutionStep + 1, true);
  };
  const previousSolutionStep = () => {
    setSolutionStep(showSolutionStep - 1, true);
  };

  const updateCubeColor = (face, index) => {
    if (!selectedFillColor || scene >= window.SCENE_SHOW_SOLUTION_STEPS) return;
    let newFace = cubeState[face];
    newFace =
      newFace.substring(0, index) +
      selectedFillColor +
      newFace.substring(index + 1);
    // onderstaande voor het doorklikken om kleuren te kiezen
    // newFace =
    //   newFace.substring(0, index) + color + newFace.substring(index + 1);
    const newCubeState = { ...cubeState, [face]: newFace };
    const newCubeStateString = getCubeStateString(newCubeState);

    switch (scene) {
      case window.SCENE_COLOR_FIRST_CUBIE:
        nextScene();
        break;
      case window.SCENE_ASK_TO_COLOR_ALL:
        // if (newFace.indexOf("-") === -1) {
        //   //one face completely filled
        //   nextScene();
        // }
        break;
      default:
    }

    let notificationText = "";
    for (var i = 0; i < window.cubeColors.length; i++) {
      var count = newCubeStateString.split(window.cubeColors[i]).length - 1;
      if (count > 9) {
        //er passen er maar 9 in een vlak
        let overflow = count - 9;
        if (overflow == 1) {
          notificationText += `Je hebt 1 kubus te veel de kleur <i>${
            window.cubeColorNames[window.cubeColors[i]]
          }</i> gekleurd.<br />`;
        } else {
          notificationText += `Je hebt ${overflow} hokjes te veel met <i>${
            window.cubeColorNames[window.cubeColors[i]]
          }</i> gekleurd. <br />`;
        }
      }
    }
    if (notificationText.length) {
      setNewNotification(notificationText);
      setColorMisMatch(true);
    } else {
      if (
        areAllCubiesFilled(newCubeStateString) &&
        scene === window.SCENE_ASK_TO_COLOR_ALL
      ) {
        nextScene();
      }
      setNotifications([]);
      setColorMisMatch(false);
    }
    writeCubeStateToLocalStorage(newCubeState);
    setCubeState(newCubeState);
    setAllCubiesAreColored(areAllCubiesFilled(cubeStateString));
  };

  const calculateSolution = () => {
    sendAnalyticsEvent("Los mijn kubus op");
    setSolutionSteps(false); // om de timer te helpen;
    window.webworker.postMessage({
      cmd: "solve",
      cubeStateString: cubeStateString,
    });
  };

  /* See if a face should be highlighted */
  let highlightedFace = false;
  if (scene === window.SCENE_SHOW_SOLUTION_STEPS) {
    if (showSolutionStep !== null && showSolutionStep < solutionSteps.length) {
      /* Set highlighted face */
      switch (solutionSteps[showSolutionStep || 0]["move"].substring(0, 1)) {
        case "R":
          highlightedFace = "right";
          break;
        case "L":
          highlightedFace = "left";
          break;
        case "F":
          highlightedFace = "front";
          break;
        case "U":
          highlightedFace = "up";
          break;
        case "B":
          highlightedFace = "back";
          break;
        case "D":
          highlightedFace = "down";
          break;
        default:
          highlightedFace = false;
          break;
      }
    }
  }

  if (loadingCubeAppContent) {
    return <div className="app loading"></div>;
  }

  return (
    <>
      <Navigation />
      <div className="container container--grid">
        <div
          className={`app
      ${
        scene >= window.SCENE_COLOR_FIRST_CUBIE &&
        scene < window.SCENE_SHOW_SOLUTION_STEPS
          ? "user-is-coloring"
          : ""
      }
      `}
        >
          <div className="appContainer container">
            {scene < window.SCENE_SHOW_SOLUTION_STEPS ? (
              <ScenarioExplainer
                scene={scene}
                nextScene={nextScene}
                previousScene={previousScene}
                colorMisMatch={colorMisMatch}
                cubeSolverInitialized={cubeSolverInitialized}
                appIsCalculating={appIsCalculating}
                setAppIsCalculating={setAppIsCalculating}
                calculateSolution={calculateSolution}
                stepInfo={cubeAppContent.steps}
              />
            ) : null}
            {scene === window.SCENE_SHOW_SOLUTION_STEPS ? (
              <SlideShow
                scene={scene}
                slides={cubeAppContent.slides}
                slideDuration={cubeAppContent.slideDuration}
              />
            ) : null}
            {scene !== window.SCENE_CUBE_SOLVED ? (
              <Cube
                scene={scene}
                highlightedFace={highlightedFace}
                solutionSteps={solutionSteps}
                hideCube={hideCube}
                cubeState={cubeState}
                updateCubeColor={updateCubeColor}
                setScene={setScene}
                showSolutionStep={showSolutionStep}
                cubeRotation={cubeRotation}
                setSelectedFillColor={setSelectedFillColor}
                selectedFillColor={selectedFillColor}
                previousScene={previousScene}
                nextScene={nextScene}
                appDesiredPerspective={appDesiredPerspective}
                setSolutionStep={setSolutionStep}
                nextSolutionStep={nextSolutionStep}
                previousSolutionStep={previousSolutionStep}
                setCubeState={setCubeState}
                getCubeStateFromString={getCubeStateFromString}
              />
            ) : (
              <FinalVideo content={cubeAppContent.final} />
            )}

            <Cursor selectedFillColor={selectedFillColor} scene={scene} />
            {appIsCalculating && (
              <div className="overlay">
                {/* we berekenen de oplossing.... */}
              </div>
            )}

            <Notifications
              notifications={notifications}
              setNotifications={setNotifications}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default App;
