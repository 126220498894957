import { useState } from "react";

const ColorPallete = ({
  selectedFillColor,
  setSelectedFillColor,
  scene,
  nextScene,
}) => {
  const [hoveredColor, setHoveredColor] = useState(null);
  const [hiddenCursorColor, setHiddenCursorColor] = useState(null);

  let colorArray = [];
  for (var i = 0; i < window.cubeColors.length; i++) {
    colorArray.push(window.cubeColors[i]);
  }

  let indicatorPosition = 0;
  if (hoveredColor !== null) {
    indicatorPosition = hoveredColor;
  } else if (selectedFillColor) {
    indicatorPosition = window.cubeColors.indexOf(selectedFillColor)
      ? window.cubeColors.indexOf(selectedFillColor)
      : 0;
  }

  return (
    <div
      className={`colorPallete ${
        scene == window.SCENE_SHOW_COLOR_PALETTE ? "showBackground" : ""
      }`}
      onMouseEnter={() => {
        setHiddenCursorColor(selectedFillColor);
        setSelectedFillColor("transparent");
      }}
      onMouseLeave={() => {
        setSelectedFillColor(hiddenCursorColor);
      }}
    >
      <div>
        {colorArray.map((color, i) => {
          return (
            <div
              onMouseEnter={() => setHoveredColor(i)}
              className={`color ${color} ${
                selectedFillColor === color ? "selected" : ""
              }`}
              key={color}
              onClick={() => {
                if (scene === window.SCENE_SHOW_COLOR_PALETTE) {
                  nextScene();
                }
                setSelectedFillColor(window.cubeColors[i]);
                setHiddenCursorColor(window.cubeColors[i]);
              }}
            ></div>
          );
        })}
        <div
          className={`indicator ${
            indicatorPosition ? "select-" + (indicatorPosition + 1) : ""
          }`}
        ></div>
      </div>
    </div>
  );
};

export default ColorPallete;
