export function areAllCubiesFilled(cubeStateString) {
  const fillState = cubeStateString.split("-").length - 1 < 1;
  return fillState;
}

export function amountOfCubiesLeftToBeFilled(cubeStateString) {
  return (cubeStateString.match(/-/g) || []).length;
}

export const getCubeStateString = (stateObject) => {
  return (
    stateObject.up +
    stateObject.right +
    stateObject.front +
    stateObject.down +
    stateObject.left +
    stateObject.back
  );
};
export const getCubeStateFromString = (stateString) => {
  return {
    up: stateString.substring(0, 9),
    right: stateString.substring(9, 18),
    front: stateString.substring(18, 27),
    down: stateString.substring(27, 36),
    left: stateString.substring(36, 45),
    back: stateString.substring(45, 54),
  };
};
