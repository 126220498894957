// import RotateSVG from "../img/icon_rotate.svg";
import { ReactComponent as RotateSVG_right } from "../img/icon_rotate_right.svg";
import { ReactComponent as RotateSVG_right2 } from "../img/icon_rotate_right-2x.svg";
import { ReactComponent as RotateSVG_left } from "../img/icon_rotate_left.svg";
import { ReactComponent as RotateSVG_left2 } from "../img/icon_rotate_left-2x.svg";
import { ReactComponent as RotateSVG_left2_mirrored } from "../img/icon_rotate_left-2x-mirrored.svg";
import { ReactComponent as RotateSVG_right2_mirrored } from "../img/icon_rotate_right-2x-mirrored.svg";
import { ReactComponent as RotateSVG_right2_mirrored_mirrored } from "../img/icon_rotate_right-2x-mirrored-mirrored.svg";
import { ReactComponent as RotateSVG_left2_mirrored_mirrored } from "../img/icon_rotate_left-2x-mirrored-mirrored.svg";

const VisualStepExplainer = ({ highlightedFace, face, move }) => {
  const renderArrow = () => {
    if (move.includes("'")) {
      //left
      if (move.includes("2")) {
        //2x
        switch (face) {
          case "back":
          case "left":
            return <RotateSVG_left2_mirrored />;
          case "down":
          case "down":
            return <RotateSVG_left2_mirrored_mirrored />;
          default:
            return <RotateSVG_left2 />;
        }
      } else {
        return <RotateSVG_left />;
      }
    } else {
      //right
      if (move.includes("2")) {
        //2x
        switch (face) {
          case "back":
          case "left":
            return <RotateSVG_right2_mirrored />;
          case "down":
            return <RotateSVG_right2_mirrored_mirrored />;
          default:
            return <RotateSVG_right2 />;
        }
      } else {
        return <RotateSVG_right />;
      }
    }
  };

  return (
    <div
      className={`stepExplainer ${face}  ${
        highlightedFace === face ? "visible" : ""
      }`}
    >
      {renderArrow()}
    </div>
  );
};

export default VisualStepExplainer;
