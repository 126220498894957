const Button = ({
  clickButton,
  label,
  type = "secondary",
  size = "normal",
}) => {
  return (
    <div
      className={`button button--${type} button--${size}`}
      onClick={clickButton}
    >
      <span>
        <span className="button__label">{label} </span>
        <svg
          version="1.1"
          x="0px"
          y="0px"
          viewBox="0 0 13.9 10.3"
          className="button__arrow"
        >
          <g transform="translate(6.983 0.748)">
            <path id="Path_82" d="M1.3,0.8l3.4,3.6L1.2,7.9" />
          </g>
          <g transform="translate(0.831 4.319)">
            <path id="Path_83" d="M1.2,0.8h9.3" />
          </g>
        </svg>
      </span>
    </div>
  );
};
export default Button;
