export function getCubeStateFromLocalStorage() {
  try {
    const cachedData = JSON.parse(localStorage.getItem("cubeState"), null, -1);
    return cachedData.state;
  } catch (err) {
    return false;
  }
}

export function writeCubeStateToLocalStorage(state) {
  try {
    localStorage.setItem("cubeState", JSON.stringify({ state }));
    return true;
  } catch (err) {
    console.log("error: ", err);
    return false;
  }
}

export function clearLocalStorage() {
  localStorage.clear();
  window.location.reload();
}
