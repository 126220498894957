const Cubie = ({ color, face, index, updateCubeColor, scene, setScene }) => {
  let classAccordiginToScene = "";

  switch (scene) {
    case window.SCENE_START:
      if (index === 4) {
        classAccordiginToScene = "active";
      }
      break;
    case 2:
    case 3:
      if (face === "right" && index === 0) {
        classAccordiginToScene = "active";
      }
      break;
    default:
      break;
  }
  return (
    <div
      className={`cubie ${color !== "-" ? color : "empty"} ${
        index === 4 ? "middle-cubie" : ""
      } ${classAccordiginToScene}`}
      onClick={() => {
        if (scene < window.SCENE_COLOR_FIRST_CUBIE && scene > 8) {
          return;
        }
        if (index === 4) return; // middle

        const currentIndex = window.cubeColors.indexOf(color);

        updateCubeColor(face, index);
      }}
    >
      {/* <span
        style={{
          top: "50%",
          left: 0,
          width: "100%",
          height: "auto",
          position: "absolute",
          zIndex: 1000,
          fontSize: "1em",
          background: "transparent",
          transform: "translateY(-50%)",
        }}
      >{`${face} - ${index}`}</span> */}
      {/* {scene === 2 && index === 0 && <span>?</span>} */}
    </div>
  );
};

export default Cubie;
